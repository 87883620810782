<template>
  <div class="tag-wrapper">
    <p class="text font-default size-default color-light">{{career}}</p>
  </div>
</template>

<script>
export default {
  name: "careerTag",
  props: {
    career: {
      type: String
    }
  }
}
</script>

<style scoped>
@import "@/styles/textStyle.css";
@import "@/styles/generalStyle.css";

.tag-wrapper {
  width: fit-content;
  height: fit-content;

  max-width: 350px;
  background-color: var(--color-grey);
  padding-inline: 5px;
  padding-block: 7px;
  border-radius: var(--border-radius-small);
}

</style>