<template>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&display=swap">
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap">
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

  <section id="application">
    <header-component/>
    <content-component/>
  </section>
</template>

<script>

import HeaderComponent from "@/components/headerComponent";
import ContentComponent from "@/components/contentComponent";

export default {
  name: 'App',
  components: {
    ContentComponent,
    HeaderComponent
  },
  mounted() {
    document.title = "Ausbildungsplatzbörse"
  },
}

</script>

<style>
@import "styles/generalStyle.css";

</style>
