<template>
  <div class="info-card horizontal">
    <div class="info-card-header">
      <h1 class="text size-heading-small font-default color-blue">{{career['companycaption1']}}</h1>
    </div>
    <!--
     <img class="career-logo" :src="'/favicon.ico'"/>
     -->
    <img class="career-logo" :src="career['image'] ? career['image'] : '/favicon.ico'"/>

    <div class="career-tags-wrapper" v-if="career['apprenticeShips'].length > 0">
      <career-tag v-if="career['apprenticeShips'][0] !== ''" :career="career['apprenticeShips'][0]"></career-tag>
      <career-tag v-if="career['apprenticeShips'][1] !== ''" :career="career['apprenticeShips'][1]"></career-tag>
    </div>


    <div class="information" v-if="showOffer">
      <div class="information-header-row" @click="toggleIsOfferOpen">
        <span class="text font-default color-blue size-heading-smaller material-symbols-outlined" v-if="!isOfferOpen">add</span>
        <span class="text font-default color-blue size-heading-smaller material-symbols-outlined" v-else>remove</span>
        <span class="text font-default color-blue size-heading-smaller">Wir bieten dir</span>
      </div>
      <div class="information-content" id="wir-bieten-dir">
        <p class="text font-default size-default">
     <!--Prüfungsvorbereitungen und Schulungen
          Teilnahme an Berufswettbewerben: Couvert d`Or im Rahmen der Intergastra/regionale Berufswettbewerbe wie die Jugendmeisterschaften
          Die Chance, uns kennen zu lernen:
          ein Schnupperpraktikum
          ein Praktikum während der Schulzeit
          ein Praktikum während der Ferien
          Mit Abi:
          Zusatzqualifikation Hotelmanagement für Hotelfachleute
          Studium an der Dualen Hochschule
          Mit Realschulabschluss:
          Teilnahme am Dualen Berufskolleg--></p>
      </div>
    </div>
    <div class="information" v-if="showAboutUs">
      <div class="information-header-row" @click="toggleIsAboutUsOpen">
        <span class="text font-default color-blue size-heading-smaller material-symbols-outlined" v-if="!isAboutUsOpen">add</span>
        <span class="text font-default color-blue size-heading-smaller material-symbols-outlined" v-else>remove</span>
        <span class="text font-default color-blue size-heading-smaller">Über uns</span>
      </div>
      <div class="information-content" id="über-uns">
        <p class="text font-default size-default">
        </p>
      </div>
    </div>
    <div class="address-wrapper">
      <h3 class="text font-default size-heading-smaller">Adresse</h3>
        <p class="text font-default size-default">{{ career['companycaption1'] }}</p>
        <p class="text font-default size-default" v-if="career['companySubCaption']">{{career['companySubCaption']}}</p>
      <p class="text font-default size-default">{{ career['addressstreet'] }} {{ career['addresshousenumber'] }}</p>
        <p class="text font-default size-default">{{ career['postalcodecaption'] }} {{ career['citycaption'] }}</p>
    </div>
    <div class="contact-wrapper">
      <div class="contact-information">
        <h3 class="text font-default size-heading-smaller">Kontakt</h3>
        <p class="text font-default size-default">{{ career['humanfirstname'] }} {{ career['humanlastname'] }}</p>
        <p class="text font-default size-default">{{ career['companycommunicationentry'] }}</p>
        <p class="text font-default size-default" v-if=" career['website']">{{ career['website'] }}</p>

      </div>
      <p class="text font-default">oder</p>
      <!--<img class="qr-code" src="https://upload.wikimedia.org/wikipedia/commons/6/61/QR_deWP.svg"/>-->
      <img class="qr-code" :src="qrcodeImageUrl" alt="QR Code" />
    </div>
    <!--<button class="text font-default size-default color-blue button">Mehr erfahren ></button>-->
    <!--<button id="print-button" class="text font-default size-default color-light button">Informationen drucken</button>-->
  </div>
</template>

<script>
import CareerTag from "@/components/minComponents/careerTag";


export default {
  name: "infoCard",
  components: {CareerTag},
  props: {
    career: {},
    showOffer: {},
    showAboutUs: {}
  },
  data() {
    return {
      isOfferOpen: false,
      isAboutUsOpen: false,
      vCardData: 'Hallo',
      isQrCodeLoaded: false,
      contactData: {
        name: 'Max Mustermann',
        address: 'Musterstraße 123',
        phone: '123456789',
        email: 'max@example.com'
      },
      qrcodeImageUrl: ''


    }
  },
  beforeMount() {
  },
  mounted() {
    this.vCardData = 'test'
    this.generateQRCode()

  },
  methods: {
    generateQRCode() {
      const vCardData = this.generateVCard();
      const apiUrl = 'https://api.qrserver.com/v1/create-qr-code/';
      const queryParams = `?data=${encodeURIComponent(vCardData)}&size=200x200`;

      this.qrcodeImageUrl = apiUrl + queryParams;
    },
    generateVCard() {
      console.log('generate v-card')
      let isEmail = this.career['companycommunicationentry'].includes('@')
      let vCardData = `BEGIN:VCARD
VERSION:3.0
FN:${this.career['humanfirstname']} ${this.career['humanlastname']}
ADR:;;${this.career['addressstreet']} ${this.career['addresshousenumber']};${this.career['citycaption']};;${this.career['postalcodecaption']};Germany;;;`

      if(isEmail) {
        vCardData += "\nTEL: "
        vCardData += `\nEMAIL:${this.career['companycommunicationentry']}`
      } else {
        vCardData += `\nTEL:${this.career['companycommunicationentry']}`
        vCardData += "\nEMAIL: "
      }

      vCardData += `\nEND:VCARD`;
      return vCardData;
    },


      // Convert JSON object to a string

      // Get the vCard data as a string
    toggleIsOfferOpen() {
      this.isOfferOpen = !this.isOfferOpen
      document.getElementById('wir-bieten-dir').classList.toggle('open')
    },
    toggleIsAboutUsOpen() {
      this.isAboutUsOpen = !this.isAboutUsOpen
      document.getElementById('über-uns').classList.toggle('open')
    }
  }
}
</script>

<style scoped>
@import "@/styles/textStyle.css";
@import "@/styles/generalStyle.css";

  .info-card {
    width: 400px;
    height: fit-content;
    z-index: 10;
    /*border-radius: var(--border-radius-big);*/
    background-color: var(--color-white);
    padding: 15px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    overflow: scroll;
  }


.info-card::-webkit-scrollbar {
  display: none;
}

  .info-card-header {
    width: 100%;
  }

  .career-logo {
    width: 100%;
  }

  .career-tags-wrapper, .icons-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 5px;
  }

  .information-header-row {
    width: 100%;
    display: flex;
    gap: 10px;
    cursor: pointer;
  }

  .contact-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .contact-information {
    width: fit-content;
  }

  .qr-code {
    width: 100px;
    height: 100px;
  }

  .button {
    border: none;
    background: none;
    margin: 10px auto auto;
    cursor: pointer;
  }

  #print-button {
    background: var(--color-highlight-blue);
    width: 100%;
    padding-block: 10px;
    text-align: center;
  }

  .information-content {
    max-height: 0;
    overflow: hidden;
  }

  .information-content.open {
    max-height: fit-content;
    overflow: visible;
  }

  @media(width: 1080px) {
    .info-card {
      max-height: 1200px;
    }
  }


  @media(width: 1920px) {
    .info-card {
      max-height: 820px;
    }
  }
</style>