<template>
  <section id="content" class="show-impressum">
  <div id="map"></div>

    <!--
    <toggle-button/>
-->
   <card-background v-if="showCareerDetail" @closeDetail="toggleShowDetail">
      <template #info-card>
        <info-card :career="selectedMarker" :show-about-us="showAboutUs" :show-offer="showOffer"/>
      </template>
    </card-background>
  </section>
  <impressum-component ref="impressum" @clickMerkasLogo="showMerkasCard"/>

</template>

<script>
import logo from '@/assets/wir_gastfreunde_logo.png'
import CardBackground from "@/components/information/cardBackground";
import InfoCard from "@/components/information/infoCard";
import companiesWithGeocodes from '@/companies_with_geocodes.json';
import vcf from "vcf";
import EventBus from "@/EventBus";
import ImpressumComponent from "@/components/impressumComponent";

export default {
  name: "contentComponent",
  components: {ImpressumComponent, InfoCard, CardBackground},
  data() {
    return {
      logo,
      map: null,
      currentLocation: { lat: 48.7758, lng: 9.1829 },
      markers: companiesWithGeocodes,
      mapOptions: {
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
        styles: [
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi.business",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road.local",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "transit",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          }
        ],
        restriction: {
          latLngBounds: {
            north: 49.8845,
            south: 47.5,  // Neuer Wert für eine südlichere Grenze
            west: 7.464722,
            east: 10.491524,
          },
        },
        // Weitere Optionen können hier hinzugefügt werden
      },
      circleOptions: {
        strokeColor: '#4285F4', // Farbe des Randes
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#4285F4', // Füllfarbe
        fillOpacity: 0.35,
      },
      showCareerDetail: false,
      selectedMarker: null,
      showOffer: true,
      showAboutUs: true,
      studioMerkasDetails:  {
        "companycaption1": "Studio Merkas e.U",
        "companySubCaption": "Spieleentwicklung & Unterhaltungssoftware",
        "website": "www.studiomerkas.com",
        "companycaption2": "Studio Merkas e.U",
        "image": 'merkas_logo.png',
        "addresstypecaption": "Primäradresse",
        "addressid": 0,
        "addressstreet": "Pischekstraße",
        "addresshousenumber": "19",
        "addresspobox": "",
        "postalcodecaption": "70184",
        "citycaption": "Stuttgart",
        "countycaption": "Main-Tauber-Kreis",
        "statecode": "BW",
        "statecaption": "Baden-Württemberg",
        "countrycode": "DE",
        "countrycaption": "Deutschland",
        "humantitlecaption": "",
        "humanfirstname": "Nikola",
        "humanlastname": "Merkas",
        "companycommunicationentry": "hello@studiomerkas.com",
        "apprenticeShips": []
      },

    }
  },
  mounted() {
    document.querySelectorAll('a').forEach(function (link) {
      link.addEventListener('click', function (event) {
        event.preventDefault(); // Verhindere das Standardverhalten des Links
        // Hier kannst du weitere Aktionen durchführen oder den Link einfach deaktiviert lassen
      });
    });
    this.getCurrentPostition()
    this.initMap()
    this.initMarkers()
    EventBus.on('scroll', isImpressumShowing => {
      console.log(isImpressumShowing)
      const content = document.getElementById('content')//.classList.toggle('show-impressum')
      console.log(content)
      if(!isImpressumShowing) {
        content.classList.remove('full-map')
        content.classList.add('show-impressum')
      } else {
        content.classList.add('full-map')
        content.classList.remove('show-impressum')
      }


    })
  //  this.removeGoogleMapsLabels()

    // Hole die aktuelle Position des Benutzers
  },
    /*this.$nextTick(() => {
      // Selektiere alle <a>-Elemente und füge einen Eventlistener hinzu
      document.querySelectorAll('a').forEach(function (link) {
        link.addEventListener('click', function (event) {
          event.preventDefault(); // Verhindere das Standardverhalten des Links
          // Hier kannst du weitere Aktionen durchführen oder den Link einfach deaktiviert lassen
        });
      });*/

      /*this.markers.forEach(marker => {
      try {
        const position = this.geocodeAddress(this.formatAdress(marker.career.address));
        marker.position = position
      } catch (error) {
        console.error('Fehler bei der Adresse:', error);
 */
  methods: {
    showMerkasCard() {
      this.showCareerDetail = false
      this.showOffer = false
      this.showAboutUs = false
      this.selectedMarker = this.studioMerkasDetails;
      setTimeout(() => {
        this.showCareerDetail = true
      }, 100)
    },
    removeGoogleMapsLabels() {
      window.google.maps.event.addListenerOnce(this.map, 'idle', function () {
        console.log('karte geladen')
        setTimeout(() => {
          const elements = document.querySelectorAll("a");
          elements.forEach(function(element) {
            element.style.pointerEvents = 'none';
          });
          /*elements.forEach(function(element) {
            element.parentNode.removeChild(element);
          });*/
          // Selektiere das Button-Element
          // Selektiere das Button-Element
          const buttonElement = document.querySelector('button[draggable="false"][aria-label="Kurzbefehle"][title="Kurzbefehle"][type="button"][style="background: none; display: inline-block; border: 0px; margin: 0px; padding: 0px; text-transform: none; appearance: none; position: relative; cursor: pointer; user-select: none; color: rgb(0, 0, 0); font-family: inherit; line-height: inherit;"]');

// Überprüfe, ob das Button-Element gefunden wurde
          if (buttonElement) {
            // Deaktiviere das Button-Element
            buttonElement.disabled = true;
            // Falls du möchtest, kannst du auch den Cursor ändern, um zu signalisieren, dass es deaktiviert ist
            buttonElement.style.cursor = 'not-allowed';
          }


          /* const spanElement = document.querySelector('span[style=""]');
           console.log(spanElement)

           if (spanElement) {
             // Entferne das <span>-Element aus dem DOM
             spanElement.parentNode.removeChild(spanElement);
           }

           const buttonElement = document.querySelector('button[draggable="false"][aria-label="Kurzbefehle"][title="Kurzbefehle"][type="button"][style="background: none; display: inline-block; border: 0px; margin: 0px; padding: 0px; text-transform: none; appearance: none; position: relative; cursor: pointer; user-select: none; color: rgb(0, 0, 0); font-family: inherit; line-height: inherit;"]');

           if (buttonElement) {
             buttonElement.parentNode.removeChild(buttonElement);
           }

           let divElement = document.querySelector('div[style="background-color: rgb(245, 245, 245); width: auto; height: 100%; margin-left: 1px;"]');

           if (divElement) {
             divElement.remove()
           }

           let divElement2 = document.querySelector('div[style="background-color: rgb(245, 245, 245); width: auto; height: 100%; margin-left: 1px;"]');

           if (divElement2) {
             divElement2.remove()
           }

           let divElement3 = document.querySelector('div[style="background-color: rgb(245, 245, 245); width: auto; height: 100%; margin-left: 1px;"]');

           if (divElement3) {
             divElement3.remove()
           }


           const divElement4 = document.querySelector('div[style="background-color: rgb(245, 245, 245); width: auto; height: 100%; margin-left: 1px;"]');

           if (divElement4) {
             divElement4.remove();
           }

           const divElement5 = document.querySelector('div[style="background-color: rgb(245, 245, 245); width: auto; height: 100%; margin-left: 1px;"]');

           if (divElement5) {
             divElement5.remove();
           }

           const spanElement2 = document.querySelector('span[style=""]');

           if (spanElement2) {
             spanElement2.remove();
           }*/



          /*elements.forEach(function(element) {
            element.style.pointerEvents = 'none';
          });*/
        }, 1000)

      });
    },
    generateVCard() {
      const vcfData = new vcf();
      vcfData.add('FN', this.contactData.name, {});
      vcfData.add('ADR', this.contactData.address, {});
      vcfData.add('TEL', this.contactData.phone, {});
      vcfData.add('EMAIL', this.contactData.email, {});

      // Get the vCard data as a string
      console.log(vcfData)
      return vcfData.toString();
    },
    initMap() {
      this.map = new window.google.maps.Map(document.getElementById('map'), {
          center: this.currentLocation,
          zoom: 12,
          options: this.mapOptions,
          minZoom: 10, // Minimales Zoomlevel
          maxZoom: 20, // Maximales Zoomlevel
      })
    },

    async initMarkers() {

      for (const markerInfo of this.markers) {
        const marker = new window.google.maps.Marker({
          position: markerInfo.geocode,
          map: this.map,
          clickable: true,
          title: markerInfo['companycaption1']
        });

        // Verwende eine Funktion mit einem Parameter für den Event Listener
        const onMarkerClick = (info) => {
            console.log('click')
            this.selectedMarker = info;
            this.showOffer = true
            this.showAboutUs = true
            setTimeout(() => {
              this.showCareerDetail = true
            }, 100)
        };

        // Füge den Event Listener mit der Handler-Funktion hinzu
        window.google.maps.event.addListener(marker, 'click', () => {
          onMarkerClick(markerInfo)
        });
      }
      /*for (const markerInfo of this.markers) {
        // eslint-disable-next-line no-unused-vars
        const position = await this.geocodeAddress(this.formatAdress(markerInfo.career.address));
        let marker = new window.google.maps.Marker({
          position: position,
          map: this.map,
          clickable: true,
          title: markerInfo.career.title
        })

        window.google.maps.event.addListener(marker, 'click', () => {
          this.onMarkerClick(markerInfo)
        });
      }*/
    },

    getCurrentPostition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            position => {
              const { latitude, longitude } = position.coords;
              this.currentLocation = { lat: latitude, lng: longitude };
            },
            error => {
              console.error('Fehler bei der Geolokalisierung:', error);
            }
        );
      } else {
        console.error('Geolokalisierung wird nicht unterstützt');
      }
    },

    onMarkerClick(marker) {
      this.selectedMarker = marker.career
      this.toggleShowDetail()
    },

    toggleShowDetail() {
      this.showCareerDetail = !this.showCareerDetail
    },

    toggleImpressum(isImpressumShowing) {
      const content = document.getElementById('content')
      if(isImpressumShowing) {
        content.classList.remove('show-impressum')
        content.classList.add('full-map')
      } else {
        content.classList.add('show-impressum')
        content.classList.remove('full-map')
      }
    },

    async geocodeAddress(address) {
      try {
        const geocoder = new window.google.maps.Geocoder();
        const results = await new Promise((resolve, reject) => {
          geocoder.geocode({ address }, (results, status) => {
            if (status === 'OK') {
              resolve(results);
            } else {
              reject('Fehler bei der Geokodierung');
            }
          });
        });

        if (results.length > 0) {
          const location = results[0].geometry.location;
          return { lat: location.lat(), lng: location.lng() };
        } else {
          throw new Error('Keine Ergebnisse gefunden');
        }
      } catch (error) {
        console.error('Fehler bei der Geokodierung:', error);
        throw error;
      }
    },

    formatAdress(adress) {
      return `${adress.street}, ${adress.place}`
    }
  }
}
</script>

<style scoped>
@import "@/styles/textStyle.css";
@import "@/styles/generalStyle.css";


#content {
  background-color: var(--color-grey);
  position: relative;
}

#content {
  height: calc(100vh - 150px);
  transition: height 1s ease-in-out;
}


#map {
  width: 100%;
  height: 100%;
}

.gm-fullscreen-control {
  display: none !important;
}

button.gm-control-active.gm-fullscreen-control {
  display: none !important;
}

a {
  display: none;
}

</style>