<template>
  <div class="card-background" @click="closeCard">
    <slot name="info-card"></slot>
  </div>
</template>

<script>
export default {
  name: "cardBackground",
  methods: {
    closeCard(event) {
      const validClasses = ['card-background']
      const classes = event.target.classList
      if (validClasses.some(className => classes.contains(className))) {
        this.$emit('closeDetail')
      }
      }
    }
  }

</script>

<style scoped>
  .card-background {
    width: 100vw;
    height: calc(100vh - 150px);
    top: 80px;
    background-color: rgba(0,0,0, .3);
    opacity: 100;
    z-index: 10;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>