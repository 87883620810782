<template>
  <section id="header-wrapper">
    <img :src="logo" id="logo"/>
    <h1 class="text heading font-heading size-heading">Ausbildungsplatzbörse</h1>
  </section>
</template>

<script>
import logo from '@/assets/wir_gastfreunde_logo.png'
export default {
  name: "headerComponent",
  components: {},
  data() {
    return {
      logo
    }
  }
}
</script>

<style scoped>
@import "@/styles/textStyle.css";
#header-wrapper {
  width: 100%;
  height: 50px;
  background-color: var(--color-white);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  padding: 15px;

}

#logo {
  height: 100%;
}

</style>