<template>
  <section id="impressum-wrapper">
    <p id="dehoga-text" class="text font-default size-heading-small">DEHOGA GmbH ©</p>
   <img @click="$emit('clickMerkasLogo')"  alt="" id="powered-by" src="/powered_by_Logo_Negative.png">
  </section>
</template>

<script>
export default {
  name: "impressumComponent"
}
</script>

<style scoped>

#impressum-wrapper {
  width: calc(100vw - 40px);
  flex: 1;
  background-color: black;
  display: flex;
  align-items: center;
  padding-inline: 20px;
  justify-content: space-between;
}

img {
  flex: 1000;
  max-width: 220px;
  height: auto;
  margin: 10px;
  object-fit: scale-down;
}

#powered-by {
text-decoration: none;
}

#dehoga-text {
  color: white;
  font-weight: normal;
}

</style>